import React from "react";
import { Tooltip, Statistic } from "antd";

const Daily = (props: any) => {
  return (
    <div className="daily-section noDrag">
      <Statistic title="RVU" value={props.totalRVU} />
      <Tooltip title="Equivalent Minutes Worked" placement="topLeft">
        <Statistic title="EMW" value={props.dailyEMW} />
      </Tooltip>
      <Statistic title="Reports" value={props.reportCount} />
    </div>
  );
};
export default Daily;
