import React, { useEffect, useState } from "react";
import { Typography, Tooltip, Alert, Spin, Result } from "antd";
import isElectron from "is-electron";
import { LoadingOutlined } from "@ant-design/icons";

// Components
import Title from "./Title";
import Study from "./components/Study";
import Daily from "./components/Daily";
import Shift from "./components/Shift";

// Utilities
import { getConfig, getDetails } from "./utils/azureFunctions";
import Details from "./utils/detailsInterface";
import { startCloseTimer } from "./utils/electronFunctions";

import "./App.less";

let rebootTimer: any;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

declare global {
  interface Window {
    api: any;
  }
}

const App = () => {
  // Electron Parameters
  const [openUsername, setOpenUsername] = useState("");
  const [accession, setAccession] = useState("");

  // Logic App Results
  const [appConfigs, setAppConfigs] = useState<any>({});
  const [details, setDetails] = useState<Details | null>(null);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (isElectron()) {
      // Initialize
      initializeApp();

      // We are not using Window Bounds!!!
      // Monitor reboot data from Electron
      // update the window's position every 60 seconds
      // window.api.receive("record-window-position", (data: any) => {
      //   console.log("record-window-position", data);
      //   const parsedBounds = JSON.parse(data);
      //   console.log(parsedBounds.bounds);
      // });
      // setInterval(() => {
      //   window.api.send("get-window-position");
      // }, 60000);

      // Triggered when new study launched with window opened
      window.api.receive("window-reopened", async (data: any) => {
        console.log("window-reopened", data);
        const parsedData = JSON.parse(data);
        const rebootUsername = parsedData.username as string;
        const rebootAccession = parsedData.accession as string;
        const rebootHostname = parsedData.hostname as string;
        const rebootAppVersion = parsedData.appVersion as string;

        // Get Details from Logic Apps
        const response = await getDetails(
          rebootUsername,
          rebootAccession,
          rebootHostname,
          rebootAppVersion
        );
        response && setDetails(response);

        // Set Electron Parameters
        setOpenUsername(rebootUsername);
        setAccession(rebootAccession);
      });

      // startCloseTimer(rebootTimer);
    }
  }, []);

  const initializeApp = async () => {
    window.api.receive("args", async (data: any) => {
      const username = data.username;
      const accession = data.accession;
      const hostname = data.hostname;
      const appVersion = data.appVersion;

      if (username && accession) {
        // Set Electron Parameters
        setOpenUsername(username);
        setAccession(accession);

        // Get Config and Details from Azure Functions
        const response = await Promise.all([
          getConfig(username, hostname, appVersion),
          getDetails(username, accession, hostname, appVersion),
        ]);
        console.log(response);

        if (response[0]) {
          let responseConfig = response[0];
          const checkForAppUpdates = responseConfig.checkForAppUpdates || false;
          if (checkForAppUpdates) {
            window.api.send("check-for-application-updates");
          }
          responseConfig.settings = responseConfig.settings
            ? JSON.parse(responseConfig.settings)
            : null;
          setAppConfigs(responseConfig);
        }
        if (response[1]) {
          setDetails(response[1]);
        } else {
          setFailed(true);
        }
      }
    });

    window.api.send("check-command-line-args");
  };

  const progressPercentage = () => {
    if (details) {
      const percentage = (details.shiftProgress / details.rvuTarget) * 100;
      return percentage;
    } else {
      return 0;
    }
  };

  const displayStudyRvu = (studyRvu: number) => {
    if (!studyRvu || studyRvu === 0) {
      return "NA";
    }
    return studyRvu.toString();
  };

  return isElectron() ? (
    <>
      <Title></Title>
      {details ? (
        <div>
          <div className={"App" /* Add class show-labels here */}>
            <div className="content-left">
              <Study
                accession={details.accession}
                studyDescription={details.studyDescription}
                studyRVU={displayStudyRvu(details.studyRVU)}
                patientClass={details.patientClass}
                // Beta Features
                showEMW={appConfigs.settings && appConfigs.settings.showEMW}
                studyEMW={details.studyEMW}
              />
              <Daily
                totalRVU={details.totalRVU}
                reportCount={details.reportCount}
                dailyEMW={details.dailyEMW}
              />
              <div className="footer-left-info">
                <Typography.Paragraph style={{ paddingTop: "0.75rem" }}>
                  <Tooltip
                    placement="topLeft"
                    title="Peer Reviews: Current/Target"
                    className="noDrag"
                  >
                    <span>
                      PR:{" "}
                      {details.peerReviewCompleted +
                        "/" +
                        details.peerReviewGoal}{" "}
                      - Pending: {details.peerReviewPending}
                    </span>
                  </Tooltip>
                </Typography.Paragraph>
              </div>
            </div>
            <div className="content-right">
              <span className={"right-title"}>{details.shiftName}</span>
              <Shift
                shiftRvuGoal={details.rvuTarget}
                progressPercentage={progressPercentage}
                shiftRVU={details.shiftProgress}
                // Beta Features
                showEMW={appConfigs.settings && appConfigs.settings.showEMW}
                shiftEMW={details.shiftEMW}
                emwTarget={details.emwTarget}
                // End Beta Features
              />
            </div>
          </div>
        </div>
      ) : failed ? (
        <Result
          status="warning"
          title="No Data"
          subTitle="No results found. Please try again."
          style={{ paddingTop: "15px" }}
        />
      ) : openUsername && accession ? (
        <div className="loading-spinner">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <Result
          status="warning"
          title="No Study Found"
          subTitle="Please launch a study from Clario."
          style={{ paddingTop: "15px" }}
        />
      )}
    </>
  ) : (
    <div style={{ padding: "3rem" }}>
      <Alert
        message="Sir, this is a browser"
        description="Please launch a study with RadHUD."
        type="error"
      />
    </div>
  );
};

export default App;
