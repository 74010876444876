import React from "react";
import { Tooltip } from "antd";

const Study = (props: any) => {
  return (
    <div className="study-section noDrag">
      <div className={"body-row"}>
        <span className={"left-title"}>{props.accession}</span>
      </div>
      <div className={"body-row"}>
        <span className={"left-title"}>
          {props.studyDescription} :{" "}
          <Tooltip title="RVU" placement="bottom">
            <span>{props.studyRVU}</span>
          </Tooltip>
          {props.showEMW && (
            <>
              {" "}
              /{" "}
              <Tooltip title="Equivalent Minutes Worked" placement="bottom">
                <span>{props.studyEMW}</span>
              </Tooltip>
            </>
          )}
        </span>
      </div>
      <div className={"body-row"}>
        <span
          className={"left-title"}
          style={
            props.patientClass === "ED"
              ? { color: "#a8071a" }
              : { color: "#555" }
          }
        >
          {props.patientClass}
        </span>
      </div>
    </div>
  );
};

export default Study;
