import { Button } from "antd";
import { CloseOutlined, MinusOutlined } from "@ant-design/icons";

import "./Title.less";

const Title = () => {
  const onClose = () => {
    window.api.send("close-window");
  };

  const onMinimize = () => {
    window.api.send("minimize-window");
  };

  return (
    <div>
      <div className="application-header">
        <span className="application-title"></span>

        <div className="header-window-controls">
          <Button className="application-control-button" onClick={onMinimize}>
            <MinusOutlined />
          </Button>
          <Button className="application-control-button" onClick={onClose}>
            <CloseOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Title;
