import React from "react";
import { Tooltip, Progress } from "antd";

const Shift = (props: any) => {
  return (
    <div className="shift-section noDrag">
      <div className="shift-rvu">
        <Tooltip title="RVU Goal">
          <span className="rvu-goal">
            <span>{props.shiftRvuGoal}</span>
          </span>
        </Tooltip>
        <Tooltip title="RVU" align={{ offset: [-23, 0] }}>
          <Progress
            percent={props.progressPercentage()}
            trailColor="rgba(255, 255, 255, 0.15)"
            className="vertical-bar"
            format={() => props.shiftRVU}
          />
        </Tooltip>
      </div>
      {props.showEMW && (
        <div className="shift-emw">
          <Tooltip title="EMW Goal" placement="topRight">
            <span className="emw-goal">{props.emwTarget}</span>
          </Tooltip>
          <Tooltip
            title="Equivalent Minutes Worked"
            placement="topRight"
            align={{ offset: [-30, 0] }}
          >
            <Progress
              percent={(props.shiftEMW / props.emwTarget) * 100}
              trailColor="rgba(255, 255, 255, 0.15)"
              className="vertical-bar"
              format={() => props.shiftEMW}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Shift;
