import axios from "axios";

// Get Config
const getConfig = async (
  username: string,
  hostname: string,
  appVersion: any
) => {
  return await axios({
    method: "post",
    url: process.env.REACT_APP_GET_CONFIG || "",
    data: { username, hostname, appVersion },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("Get Config Error: ", error);
      return null;
    });
};

// Get Details
const getDetails = async (
  username: string,
  accession: string,
  hostname: string,
  appVersion: string
) => {
  return await axios({
    method: "post",
    url: process.env.REACT_APP_GET_DETAILS || "",
    data: {
      accession,
      hostname,
      username,
      appVersion,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("Get Config Error: ", error);
      return null;
    });
};

export { getConfig, getDetails };
